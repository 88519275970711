@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    background-color:  #f6f9fe;
    min-height: 100vh;
}
.mediumSize{
    width: 20rem;
}
.bigSize{
    min-width: 75%;
    margin-left: 12.5%;
    margin-right: 12.5%;
}
.alignCenter{
    margin-inline:auto;
}
.titleDefault{
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3%;
}
.titleMain{
    font-size: 35px;
    font-weight: bold;
    margin-left: 1%;
    vertical-align: middle;
    cursor: pointer !important;
}
.cursorPointerHead{
    cursor: pointer !important;
}
.titleActivities{
    font-size: 25px;
    font-weight: bold;
    vertical-align: middle;
    padding-bottom: 1.5%;
    display: inline;
    margin-right: 40%;
}

.marginTop{
    margin-top: 7%;
}
.formBtn{
    width: 100%;
    align-content: start;
}
.bigSize{
    width:fit-content;
}
.smallMarginLabel{
    margin-left: 0.5%;
    margin-right: 1.5%;
}
.smallMargin{
    margin-left: 0.5%;
    margin-right: 0.5%;
}
.displayInline{
    display: inline;
}
.borderRadiusSm{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.smalltitle{
    font-size: 25px;
    font-weight: bold;
}
.formInputs{
    width: 100%;
    border-radius: 5px;
}
.LinkColors{
    color:rgb(167, 167, 245);
}
.LinkColors:hover{
    color:rgb(197, 155, 236);
}
.alignLeft{
    text-align: end;
    font-weight: bolder;
}
.footerPos{
    bottom:0;
    right: 0;
    left: 0;
    /* margin-top: 3%; */
}
@media only screen and (min-width: 700px) {
    .footerPos{
        position: fixed;      
        /* margin-top: 3%; */
    }
}
footer{
    margin-top: 3%;
}
.alertMSGindex{
    z-index: 2;
}
.headerBg{
    background-color: #64679E;
    color:#c8cbf5;
    z-index: 2;
}
.avatarTitle{
    color: #64679E !important;
}
.avatarTitleSize{
    font-size: large;
}
.marginBot{
    margin-bottom: 3%;
}
.marginTopnegative{
    margin-top: -2%;
}
.cardTextSize{
    font-size: 15px;
}
.cardSize{
    max-height: 100% !important;
}
.cardSize>img{
    max-height: 195px !important;
}

.smallText{
    font-size: small;
}
.borderRadiusAvatarSm{
    border-radius: 5px !important;
    padding: 5px;
}

.reserveCard{
    max-width:100% !important;
}
.reserveCard div{
    width: 100% !important;
}
.reserveCard:hover{
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.462);
    /* cursor: pointer; */
}
.onClicNav{
    cursor: pointer;
}
.addBtn{
    display: inline;
    padding-left:1% ;
    padding-right: 2%;
    border-radius: 5px;
}
.addBtn:hover{
    background-color: #8ecff8;
}
.addBtn>:first-child{
    display: inline;
}
.addBtn>:nth-child(2){
    padding-left: 7px;
    display: inline;
    font-size: 18px;
}
.deleteBtn{
    display: inline;
    width: 20%;
    height: 15%;
    padding-left: 6%;
    z-index: 2;
    border-radius: 10%;
}
.deleteBtn:hover{
    background-color: rgb(129, 0, 0);
}
.daysDiv{
    border: 2px;
    border-radius: 5px;
    background-color: #e8e8e8;
    padding-left: 5%;
}

.innerFormDiv{
    border: 2px;
    border-radius: 5px;
    background-color: #e8e8e8;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 4%;
    /* margin-top: 5%; */
}
.smallMarginTop{
    margin-top:1%;
}
.passwordRecMarginTop{
    margin-top: 3%;
}
.formFontSize{
    font-size: 20px;
    font-weight: bold;
}
.privacyPolicy{
    margin-left: 3%;
    margin-right: 3%;
    padding: 2%;
    background-color: rgba(253, 254, 255, 0.823);
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.462);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12;

}
.privacyPolicy>h2{
    font-weight: bold;
    font-size: larger;
    margin-top: 3%;
    margin-bottom: 1%;
}
.privacyPolicy>h1{
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 1%;
}
.privacyPolicyList{
    margin-left: 3% !important;
    margin-top: 1% !important;
    margin-bottom: 1%;
}
@media only screen and (min-width: 900px) {
    .mediumSize{
        width: 25rem;
    }
}