body {
  background-color: #f6f9fe;
  color: black;
  font-size: calc(10px + 2vmin);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.calendarHorizontal {
  background-color: #c8cbf5;
  color: #111728;
  border-radius: 9px;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-inline: auto;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  flex: 1;
  flex-wrap: wrap;
  width: 100vmin;
  max-width: 2900px;
  text-align: center;
  overflow: auto;
  font-size: calc(5px + 2vmin);
  padding-top: 15px;
  scroll-snap-type: inline;
  scroll-behavior: smooth;
  scroll-snap-align: right;
}

.calendarButton {
  border-radius: 9%;
  height: 120px;
  width: 50px;
  border: 6px solid #c8cbf5;
  text-align: center;
  justify-content: center;
  position: relative;
  margin-top: 70px;
  display: none;
  bottom: 25px;
}

.calendarButton:hover {
  background-color: #acb1f4;
  border-color: #64679E;
}

.calendarButton:active {
  background-color: #daddff;
  border-color: #b3b7e9ab;
}

.toastAlign {
  position: fixed;
  top: 10%;
  left: 48%;
  transform: translate(-50%, -50%);
  z-index: 2 !important;
}

.calendarWidth {
  width: 90vmin !important;
  max-width: 2900px;
  max-height: fit-content !important;
  margin-bottom: 20px;
  margin-inline: auto !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 0px;
}

.calendarHorizontal::-webkit-scrollbar {
  width: 10px;
}

.weekdays {
  text-align: center;
  font-weight: bold;
  align-content: center;
}

.daysHorizontal {
  display: flex;
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  padding-right: 20px;
  margin-right: 20px;
}

.daysHorizontal :hover {
  background-color: #64679E;
  background-size: 10% 80%;
  color: white;
  border-radius: 9px;
  transform: scale(1.01, 1.01);
  transform: translate(0px, -3px);
}

.daysHorizontal :hover div {
  color: black;
  background: none;
  border: none;
  transform: none;
}

.daysHorizontal :hover .whiteLetter {
  color: #FFFFFF !important;
}

.daysContainer {
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  width: 90px;
  color: #111728;
}

.day {
  font-size: 45px !important;
}

.month {
  font-size: 45px !important;
}

.daysBox {
  border: solid 1px #c8cbf5;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-inline: -55px;
  border-radius: 9px;
  background-color: #f6f9fe;
}

.daysBox :hover {
  border: solid 10px rgb(255, 0, 0);
}

.yearOfDay {
  font-weight: lighter;
  color: #3F4554;
}

.dayOfWeekLetter {
  font-size: calc(7px + 2vmin);
}

.dayLetter {
  font-size: calc(11px + 2vmin);
}

.daySelected {
  background-color: #ebecffc1;
  border-radius: 9px;
}

.rbc-allday-cell {
  display: none !important;
}

.headerIMG {
  z-index: 1;
  width: 100%;
  height: 35vmin;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 80%, #f6f9fe 100%), url("./resources/teatro.jpg");
  pointer-events: none;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5));
  justify-content: center;
  text-align: center;
  mix-blend-mode: multiply;
  mask-image: linear-gradient(to top, transparent, #f6f9fe 30%);
  margin-top: -17px;

}

.headerIMG div {
  position: absolute;
  margin-top: -1vh !important;
  text-align: center;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.headerIMG h1 {
  font-size: 55px;
}

.headerColorTitle {
  color: white;
}

.activityDetailsLabel {
  margin-left: 20px;
}

h2 {
  margin-inline: auto !important;
  padding-left: 1px;
  width: auto;
}

.horizontalCalendarCol {
  display: inline-flex;
  width: 80% !important;
  justify-content: center !important;
  justify-items: center !important;
  text-align: center !important;
  position: relative;
}

.center {
  width: auto;
  padding-left: 20px;
}
.row {
  width: 90%;
  justify-content: center;
  justify-items: center;
  align-items: center !important;
  display: inherit;
}

.horizontalCalendarRow {
  width: 100%;
  align-items: center;
  display: block;
  justify-content: center !important;
  margin-inline: auto !important;
}

.weekEnd {
  color: red;
}

.firstDayOfWeek {
  background-color: #acb1f4;
  border-radius: 9px;
}

.bloquedDay {
  color: #3F4554 !important;
  background-color: #b3b6e4 !important;
}

.marginLeftBig {
  margin-left: 60%;
}

.rbc-timeslot-group {
  min-height: 6vh !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid white;
  color: #111728;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid black;
  color: #111728;
}

.row {
  width: 90%;
}

.marginLeftBig {
  margin-left: 60%;
}

.alertDelete {
  padding-left: 5% !important;
  text-align: center;
}

.alertDeleteText {
  padding-left: 36% !important;
}

.dialogMinWidth {
  min-width: 420px;
}

.centerContentDialog {
  font-size: 18px !important;
  padding-inline: 19%;

}

.divToast {
  /* width: 100%; */
  padding-right: 11%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 15em;
  top: 10;
  z-index: 2;
}

.rbc-event {
  min-height: 5px;
  width: 100% !important;
  left: 0% !important;
}

.displayNone {
  display: none;
}

.deleteButton {
  background-color: rgb(236, 36, 36);
}
.imgJuntaFooter{
  width: 50% !important;
  margin: auto;
}
.imgJuntaAbout{
  width: 50% !important;
  margin: auto;
}
.imgPanelAbout{
  width: 75% !important;
  margin: auto;
}
.divAbout{
  width: 75% !important;
  margin: auto;
  margin-top: 1rem;
}
.imgJuntaFooter{
 display: none;
}
.footerSpan{
  min-width: max-content;
}
.footerUl{
  min-width: fit-content;
}
@media only screen and (min-width: 658px) {
  .calendarHorizontal {
    width: 100vmin !important;
  } 
  .divAbout{
    width: 30% !important;
    margin:auto;
    margin-top: 1rem;
  }

  .calendarWidth {
    width: 30rem !important;
    max-height: fit-content !important;
    margin-bottom: 20px;
    margin-inline: auto !important;
    padding-left: 10px;

  }
  .row{
    width: 100vmin;
  }
  .activityDetailsLabel {
    margin-left: 10px;
  }
  .fullWidth{
    width: 100vmin;
  }
  .center{
    padding-left: 149px;
  }
  .centerWithPadding{
    padding-left: 149px;
  }
}
@media only screen and (min-width: 958px) {

  .imgJuntaFooter{
    display:block;
    width: 25% !important;
    margin-left: 25%;
    margin-right: 25%;
  }
  .calendarHorizontal {
    width: 90% !important;
  }
  .imgPanelAbout{
    width: 45% !important;
    margin: auto;
  }
  .calendarWidth {
    width: 40rem !important;
    max-height: fit-content !important;
    margin-bottom: 20px;
    margin-inline: auto !important;
    padding-left: 10px;

  }

  .headerIMG {
    height: 34vh;
    margin-top: -17px;
  }

  .headerIMG div {
    margin-top: 4vh !important;
  }

  .headerIMG h1 {
    font-size: 10vh;
  }

  .calendarButton {
    display: block;
  }

  .center {
    display: block;
    justify-content: center !important;
    justify-items: center !important;
    text-align: center !important;
    position: relative;
  }

  .centerWithPadding {
    padding-left: 11.6rem !important;

  }
  
  .contentAlign {
    text-align: center;
    padding-inline: 20px;
  }

  .toastAlign {
    position: fixed;
    top: 10%;
    left: 99%;
    transform: translate(-100%, -100%);
    z-index: 2;
  }

  .formBtnNoImage{
    padding-left: 55px;
  }

  .row {
    width: 100%;
  }

  .activityDetailsLabel {
    margin-left: 60px;
  }

}

.alignCenter {
  margin-inline: auto;
  position: relative;
  display: block;
  justify-items: center;
}

@media only screen and (min-width: 950px) {

  .activityDetailsLabel {
    margin-left: 60px;
  }


  .alignCenter {
    margin-inline: auto;
  }
  .fullWidth{
    width: 90%;
  }


}

@media only screen and (max-width: 900px) {
  .calendarWidth {
    width: 22rem !important;
    max-height: fit-content !important;
    margin-bottom: 20px;
    margin-inline: 36px !important;
  }

  .activityDetailsLabel {
    margin-left: 38px;
  }

  .alignCenter {
    margin-inline: auto;
  }

  .dialogMinWidth {
    min-width: 220px;
  }
  .centerWithPadding {
    padding-left: 35px !important;

  }
  
}